import { setup } from '@pinto/api-client';
import getEnvVariables from "./getEnv";


export default function setupApiClient (token?: string) {
    const env = getEnvVariables()
    setup({
        apiLegacyUrl: env.apiLegacyUrl,
        apiV2Url: env.apiV2Url
    });

    if (token != null) {
        setup({
            apiV2Token: token,
            apiLegacyToken: token,
        });
    }
}

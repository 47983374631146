interface EnvVars {
    apiV2Url: string;
    apiLegacyUrl: string;
    env: string;
}

const getEnvVariables = (): EnvVars => {
    const windowObj = window as any;
    const apiV2Url = windowObj.REACT_APP_API_V2_URL
        ? windowObj.REACT_APP_API_V2_URL
        : process.env.REACT_APP_API_V2_URL;
    const apiLegacyUrl = windowObj.REACT_APP_API_LEGACY_URL
        ? windowObj.REACT_APP_API_LEGACY_URL
        : process.env.REACT_APP_API_LEGACY_URL;
    const env = windowObj.REACT_APP_ENVIRONMENT
        ? windowObj.REACT_APP_ENVIRONMENT
        : process.env.REACT_APP_ENVIRONMENT;

    return {
        apiV2Url,
        apiLegacyUrl,
        env,
    };
};

export default getEnvVariables;
